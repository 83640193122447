
















































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProduct, IStore, IUser, PayloadState } from '@/types/types';
const productsModule = namespace('products');
const authModule = namespace('auth');
@Component({
  name: 'GetLink',
  components: {
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @productsModule.Getter('getProductDetails') details!: IProduct;
  @authModule.Getter('getUserDetails') userDetails!: IUser & IStore;
  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;

  generatedLink: string = '';


  copyLink() {
    this.$copyText(this.generatedLink)
      .then(data => {
        this.$store.dispatch('snackBarMessage', `Copied ${data.text}`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      })
      .catch(error => {
        this.$store.dispatch('snackBarMessage', error, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      });
  }
  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'add',
      state: false,
    };
  }
}
